/* General styles */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #EEF2F3;
    color: #666666;
    margin: 0;
    padding: 0;
}

.card-container {
    display: flex;
    justify-content: center;
    padding: 16px;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 0;
    }
}

.card {
    border: none;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    background-color: #FFFFFF;
    padding: 20px;
    margin-top: 20px;
}

.patient-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: 600;
    color: #2DAAAC;
}

.test-date {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    color: #666666;
    margin-bottom: 10px;
}

.test-name {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: #4139B0;
    margin-bottom: 10px;
}

/* results */

.result-group {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
}
p {
    font-size: 1rem;
    font-weight: 400;
    color: #666666;
    margin-bottom: 10px;
}

.result-item, .result-item p {
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: 600;
    color: #4139B0;
}

.reference-range, .reference-range p {
    font-size: 1rem;
    font-weight: 600;
    color: #2DAAAC;
    margin-bottom: 10px;
}

.interpretation, .interpretation p {
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
}

.summary, .summary p {
    border: none;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: #FFFFFF;
    padding: 20px;
    font-size: 1rem;
    font-weight: 600;
    color: #666666;
}

.summary::before {
    content: "!";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #4139B0;
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    line-height: 20px;
    margin-right: 10px;
}

.important-note, .important-note p {
    border: none;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: #4139B0;
    padding: 20px;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
}

.important-note::before {
    content: "!";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    color: #4139B0;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    line-height: 20px;
    margin-right: 10px;
}