body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f4f4f4;
    padding: 20px;
}

h1, h2, h3, h4 {
    color: #5687E7;
    margin-bottom: 10px;
}

h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
}

h2 {
    font-size: 2rem;
    border-bottom: 2px solid #27E2D9;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

h3 {
    font-size: 1.5rem;
    margin-top: 20px;
}

h4 {
    font-size: 1.2rem;
    margin-top: 15px;
}

p {
    margin-bottom: 15px;
}

strong {
    color: #5687E7;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
}

hr {
    border: 0;
    height: 1px;
    background: #27E2D9;
    margin: 20px 0;
}

.card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.card-header {
    text-align: center;
    margin-bottom: 20px;
}

.card-content {
    padding: 20px;
}

.card-footer {
    text-align: center;
    margin-top: 20px;
    font-size: 0.9rem;
    color: #666;
}
